<template>
  <div class="risk-point-danger-list">
    <van-nav-bar
      fixed
      title="危险有害列表"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div class="danger-list">
      <div v-for="item in list" :key="item.id" class="danger-item">
        <div class="danger-item-title">
          <span :class="'level level' + item.controlRiskLevel">{{
            item.controlRiskLevelName
          }}</span
          >{{ item.hazardousFactors }}
        </div>
        <div class="danger-item-tag">
          <span class="dtag"
            ><i class="iconfont iconfont-guankongcengji"></i
            >{{ item.riskHierarchyName }}</span
          >
          <span class="dtag"
            ><i class="iconfont iconfont-shigu"></i
            >{{ item.eventTypeName }}</span
          >
          <span class="dtag"
            ><i class="iconfont iconfont-weixianyouhaiyinsuleixing"></i
            >{{ item.dangerFactorName }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: []
    };
  },
  created() {
    const { controlList } = this.$route.params;
    console.log("controlList", controlList);
    this.list = controlList;
  },
  methods: {
    onClickLeft() {
      history.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.risk-point-danger-list {
  padding: 60px 0 20px 0;
  .danger-list {
    .danger-item {
      padding: 16px;
      border-bottom: 8px solid #eceef2;
      &-title {
        line-height: 20px;
        font-size: 14px;
        color: #3b4664;
        .level {
          font-size: 12px;
          border-radius: 2px;
          margin-right: 8px;
          padding: 0px 5px;
          box-sizing: border-box;
          white-space: nowrap;
          vertical-align: middle;
          &.level1 {
            background-color: #ffdddd;
            color: #f13030;
            border: 1px solid #f13030;
          }
          &.level2 {
            background-color: #ffe7db;
            color: #ff6d1e;
            border: 1px solid #ff6d1e;
          }
          &.level3 {
            background-color: #fffbe9;
            color: #edc400;
            border: 1px solid #edc400;
          }
          &.level4 {
            background-color: #dcecff;
            color: #1580ff;
            border: 1px solid #1580ff;
          }
        }
      }
      &-tag {
        margin-top: 8px;
        font-size: 12px;
        .dtag {
          margin-right: 20px;
          .iconfont {
            font-size: 12px;
            color: #aeb3c0;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
